import React from 'react'
import { Link } from 'gatsby'
import Time from '../Time'
import { GatsbyImage } from 'gatsby-plugin-image'

const CategoryHeroCard = ({ posts, catColor }) => (
  <div className="relative">
    <div className="bg-forbes-site-white">
      {posts &&
        posts.slice(0, 1).map((p, i) => {
          return (
            <div className="my-6 mx-2" key={i}>
              <div className="grid md:grid-cols-2 gap-4 items-top">
                <div>
                  <Link to={`/${p.node_locale}/posts/${p.slug}`}>
                    <figure className="mb-4 object-cover relative">
                      {p.postImage ? (
                        <GatsbyImage image={p?.postImage?.gatsbyImageData} />
                      ) : null}
                      <div
                        className={`${
                          `bg-` + catColor
                        } label-on-hero-item text-white absolute z-10 text-center uppercase font-bold p-auto block font-xs px-3 py-2`}
                      >
                        {p?.postSubCategories?.subCategories}
                      </div>
                    </figure>
                  </Link>
                </div>
                <div>
                  <h1 className="hover:underline tracking-wide mt-4 mb-3">
                    <Link
                      className="BlogRollTitle"
                      to={`/${p.node_locale}/posts/${p.slug}`}
                    >
                      {p.postTitle}
                    </Link>
                  </h1>
                  <div className="text-forbes-type-black text-xs mt-4 font-sans font-normal">
                    <span className="b_r">
                      <Time pubdate date={p.postDate} />
                    </span>
                    By{' '}
                    <span className="font-sans text-forbes-type-black font-bold px-1">
                      {p.postAuthor.fullName} {''}
                    </span>
                    <span className="text-forbes-type-black text-xs font-sans font-normal">
                      {p.postAuthor.position}
                    </span>
                  </div>
                  <p className="BlogRollPostContent mt-3">
                    {p.postDescription}
                  </p>
                  <Link to={`/${p.node_locale}/posts/${p.slug}`}>
                    <p className="text-forbes-read-more text-md underline mt-3 font-geo font-normal">
                      Read more
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  </div>
)

export default CategoryHeroCard
